import { Subscription } from 'rxjs'
import { writable } from 'svelte/store'

export const maxAuctionNumber = writable(0)
export const minAuctionNumber = writable(0)
export const maxUpcomingAuctionNumber = writable(0)

export const auctions = writable<Maybe<AuctionResponse[]>>(null)

export const auctionListSubscription = writable<SomeObject | null>(null)
export const auctionsSubscription = writable<Maybe<UpcomingAuction[]>>(null)
export const auctionsNoSubscription = writable<Maybe<PastAuction[]>>(null)
export const activeSubscription = writable<Subscription | null>(null)
export const requestAuctionsFromPage = writable<'home' | 'upcoming' | 'result' | 'auctiondetails'>('home')

export const auctionFilter = writable<Maybe<AuctionFilter>>()
export const activeAuction = writable<Maybe<AuctionInfo>>(null)

export const auctionAccounts = writable<Maybe<AuctionAccountsResponse>>(null)

export const auctionLotGallery = writable<AuctionLotGallery | null>(null)
export const auctionLotVideoGallery = writable<AuctionLotVideoGallery | null>(null)

export const auctionByIdResponse = writable<AuctionDetailResponse | null>(null)
export const listingByAuctionIdResponse = writable<LotsResponse | null>(null)

export const imagePreviewInfo = writable<{ imageZoomLevel: number; activeIndex: number; imagesUrl: string[] } | null>(null)
export const lotLabel = writable('')

export const maxFilterDateRange = writable(89)
export const defaultResultFilterDateRange = writable(29)
